import { gql }              from '@apollo/client';
import { client as apollo } from '../context';

export const GQL_QUERY_PUT_KEY = gql`
  mutation createRecoveryKey ($input: CreateRecoveryKeyInput!) {
    createRecoveryKey(input: $input) {
      recoveryKey
    }
  }
`;

export function createKey (input) {
  return apollo.mutate({
    mutation:  GQL_QUERY_PUT_KEY,
    variables: { input }
  })
}
