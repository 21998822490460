import devCreds from './aws-exports';

const stage = process.env.REACT_APP_STAGE || 'dev';

const awsmobile = stage === 'production' ? {
  "region": process.env.REACT_APP_APPSYNC_REGION,
  "graphqlEndpoint": process.env.REACT_APP_APPSYNC_ENDPOINT,
  "authenticationType": process.env.REACT_APP_APPSYNC_AUTHTYPE,
  "apiKey": process.env.REACT_APP_APPSYNC_APIKEY,
} : devCreds;

export default awsmobile;
