import Container    from '@material-ui/core/Container';
import CssBaseline  from '@material-ui/core/CssBaseline';
import Box          from '@material-ui/core/Box';
import Copyright    from './Copyright';


export default function Layout ({ children }) {
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      {children}
      <Box mt={5}>
        <Copyright />
      </Box>
    </Container>
  )
}
