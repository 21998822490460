import { gql }              from '@apollo/client';
import { client as apollo } from '../context';

export const GQL_QUERY_GET_KEY = gql`
  query checkForDuplicate ($key: ID!) {
    key: getRecoveryKey(recoveryKey: $key) {
      recoveryKey
      machineModel
      owner
      machineName
      recoveryType
      bitlockerIdentifier
    }
  }
`;

export const GQL_QUERY_LIST_BY_BL_ID = gql`
  query checkForDuplicateBL ($id: String!) {
    keys: listRecoveryKeyByBitlockerID(id: $id) {
      recoveryKey
      machineModel
      owner
      machineName
      recoveryType
      bitlockerIdentifier
    }
  }
`;

export function checkForDuplicate (key) {
  return apollo.query({
    query:  GQL_QUERY_GET_KEY,
    variables: { key }
  })
    .then(({ data }) => data.key)
}
export function checkForDuplicateBlId (id) {
  return apollo.query({
    query:  GQL_QUERY_LIST_BY_BL_ID,
    variables: { id }
  })
    // .then(res => {console.log(res); return res;})
    .then(({ data }) => data.keys)
}
