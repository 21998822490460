import MuiAlert from '@material-ui/lab/Alert';
import MuiSnackbar from '@material-ui/core/Snackbar';


function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Snackbar ({open, onClose, message, type, duration = 6000, ...props}) {
  return (
    <MuiSnackbar open={open} autoHideDuration={duration} onClose={onClose}>
      <Alert onClose={onClose} severity={type}>
        {message}
      </Alert>
    </MuiSnackbar>
  );
}
