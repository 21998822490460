import { AppBar, Paper, Tab, Tabs } from '@material-ui/core';

import Avatar          from '@material-ui/core/Avatar';
import Box             from '@material-ui/core/Box';
import Button          from '@material-ui/core/Button';
import Grid            from '@material-ui/core/Grid';
import Link            from '@material-ui/core/Link';
import { makeStyles }  from '@material-ui/core/styles';
import TextField       from '@material-ui/core/TextField';
import Typography      from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutline';

import CloudQueueIcon          from '@material-ui/icons/CloudQueue';
import ErrorIcon               from '@material-ui/icons/ErrorOutline';
import clsx                    from 'clsx';
import { DropzoneAreaBase }    from 'material-ui-dropzone';
import { useEffect, useState } from 'react';

import { checkForDuplicate, checkForDuplicateBlId, createKey } from './data'
import Snackbar                                                from './Snackbar';


const useStyles = makeStyles((theme) => ({
  paper:          {
    marginTop:     theme.spacing(8),
    display:       'flex',
    flexDirection: 'column',
    alignItems:    'center',
  },
  avatar:         {
    margin:          theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form:           {
    width:     '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit:         {
    margin: theme.spacing(3, 0, 2),
  },
  errIcon:        {
    color:       theme.palette.error.main,
    marginRight: theme.spacing(1)
  },
  checkIcon:      {
    marginRight: theme.spacing(1)
  },
  checkIconGreen: {
    color: theme.palette.success.main
  },
  centerLink:     {
    display:   'block',
    textAlign: 'center'
  },
  errText:        {
    color:     theme.palette.grey[400],
    display:   'block',
    textAlign: 'center'
  }
}));


const fileReg = /Identifier:[\n\s]+([\w\d-]+).+Recovery Key:[\n\s]+([\w\d-]+)[\r\n]/is;

export default function Page () {
  const [ owner, _setOwner ]                = useState('');
  const [ macName, _setMacName ]            = useState('');
  const [ macModel, setMacModel ]           = useState('');
  const [ blData, _setBlData ]              = useState({});
  const [ ready, setReady ]                 = useState(false);
  const [ fvRecovery, _setFvRecovery ]      = useState('');
  const [ snackbar, setSnackbar ]           = useState({ open: false, message: '', duration: 60000, type: 'info' });
  const [ foundQueryErr, setFoundQueryErr ] = useState({})
  const [ selectedTab, setSelectedTab ]     = useState(0);

  const classes = useStyles();

  useEffect(() => {
    let hasData;
    switch (selectedTab) {
      case 0:
        hasData = blData.id && blData.key;
        break;
      case 1:
        hasData = fvRecovery;
        break;
      default:
        hasData = false;
    }

    setReady(
      owner
      && macName
      && macModel
      && hasData
      && !foundQueryErr.data
    )
  }, [ owner, macModel, macName, fvRecovery, selectedTab, blData, foundQueryErr ])


  const setFvRecovery = (value) => {
    _setFvRecovery(value);

    checkKeyUnique(value)
      .then(() => {
        setFoundQueryErr({})
      })
      .catch(err => {
        setFoundQueryErr(err)
        setSnackbar(s => ({
          ...s,
          open:    true,
          message: err.message,
          type:    'error'
        }));
      })
  }

  const sbarClose = () => setSnackbar(s => ({ ...s, open: false }));

  const setOwner   = (e) => {
    const val = (e.target.value || '').toLowerCase();
    _setOwner(val);
  }
  const setMacName = (e) => {
    const val = (e.target.value || '')
      .toUpperCase()
      .trim()
    ;
    _setMacName(val);
  }

  const parseFile = async (files) => {
    const reader  = new FileReader();
    reader.onload = async (e) => {
      const text = e.target.result.toString();
      console.log(`"${text}"`, fileReg.test(text), text.match(fileReg));

      if (fileReg.test(text)) {
        const [ _, id, key ] = text.match(fileReg);

        _setBlData({ id, key });
        setSnackbar(s => ({
          ...s,
          open:    true,
          message: 'Checking database for duplicate',
          type:    'info'
        }));

        checkIdUnique(id)
          .then(() => {
            setSnackbar(s => ({
              ...s,
              open:    true,
              message: 'BitLocker Key not yet in database',
              type:    'info'
            }));
          })
          .catch(err => {
            setFoundQueryErr(err)
            setSnackbar(s => ({
              ...s,
              open:    true,
              message: err.message,
              type:    'error'
            }));
          })
        ;
      } else {
        setSnackbar(s => ({
          ...s,
          open:    true,
          message: 'Failed to find BitLocker data',
          type:    'error'
        }));
      }
    };
    reader.readAsText(files[0]);
    setFoundQueryErr({})
    setSnackbar(s => ({
      ...s,
      open:    true,
      message: 'Checking file for BitLocker data',
      type:    'info'
    }));
  }

  const resetForm = () => {
    _setOwner('');
    _setMacName('');
    setMacModel('');
    _setBlData({ id: '', key: '' });
    setFoundQueryErr({});
    setFvRecovery('');

    setSnackbar(s => ({
      ...s,
      open:     true,
      duration: 2000,
      message:  'Form Reset',
      type:     'success'
    }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let recData;
    let service;
    switch (selectedTab) {
      case 0:
        recData = { recoveryKey: blData.key, bitlockerIdentifier: blData.id };
        service = 'BitLocker';
        break;
      case 1:
        recData = { recoveryKey: fvRecovery };
        service = 'FileVault';
        break;
      default:
        throw new Error('No Tab Selected');
    }


    let newKey;
    try {

      newKey = await createKey({
        machineModel: macModel,
        machineName:  macName,
        owner:        owner,
        recoveryType: service.toLowerCase(),
        ...recData,
      })

      resetForm();
      setSnackbar(s => ({
        ...s,
        open:    true,
        message: `${service} key saved successfully`,
        type:    'success'
      }));
    } catch (err) {
      console.error(err.stack);
      setSnackbar(s => ({
        ...s,
        open:    true,
        message: `Problem saving ${service} data: ${err.message}`,
        type:    'error'
      }));

    }

  }


  return (
    <>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <CloudQueueIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          BitLocker Recovery Key Storage
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                autoComplete="bl-owner"
                name="owner"
                variant="outlined"
                required
                fullWidth
                id="owner-txt"
                label="Machine Owner"
                autoFocus
                value={owner}
                onChange={setOwner}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="machine-name-txt"
                label="Machine Name"
                name="machine-name"
                autoComplete="bl-macName"
                value={macName}
                onChange={setMacName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="machine-model-txt"
                label="Machine Model"
                name="machine-model"
                autoComplete="bl-macModel"
                value={macModel}
                onChange={e => setMacModel(e.target.value)}
              />
            </Grid>

            <Grid item xs={12}>
              <Box component={Paper}>
                <AppBar position="static">
                  <Tabs variant="fullWidth" value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}
                        aria-label="OS Tab Switch">
                    <Tab label="BitLocker" {...a11yProps(0)} />
                    <Tab label="FileVault" {...a11yProps(1)} />
                  </Tabs>
                </AppBar>

                <TabPanel value={selectedTab} index={0}>

                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DropzoneAreaBase
                        filesLimit={1}
                        acceptedFiles={[ 'text/plain' ]}
                        dropzoneText="Drag bitlocker recovery key here"
                        onDrop={parseFile}
                        showAlerts={false}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="bl-id-txt"
                        label="BitLocker ID"
                        name="bl-id"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete={false}
                        disabled
                        value={blData.id}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        name="bl-key"
                        label="BitLocker Key"
                        type="bl-key"
                        id="bl-key-txt"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        autoComplete={false}
                        disabled
                        value={blData.key}
                      />
                    </Grid>
                  </Grid>
                </TabPanel>

                <TabPanel value={selectedTab} index={1}>
                  <Grid container spacing={2}>

                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="filevault-recovery-key"
                        label="FileVault Recovery Key"
                        name="filevault-recovery-key"
                        value={fvRecovery}
                        onChange={e => setFvRecovery(e.target.value)}
                      />
                    </Grid>

                  </Grid>
                </TabPanel>
              </Box>

            </Grid>

          </Grid>

          {foundQueryErr.data
           ? foundQueryErr.data.map(d => (
              <Typography className={classes.errText}>
                ID Matches ${d.owner} | ${d.machineName} | ${d.machineModel}
              </Typography>
            ))
           : <Typography className={classes.errText}>&nbsp;</Typography>
          }

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!ready}
            onClick={handleSubmit}
          >
            {foundQueryErr.data ? (
              <ErrorIcon className={classes.errIcon} />
            ) : (
               <CheckCircleIcon className={clsx({
                 [classes.checkIcon]:      true,
                 [classes.checkIconGreen]: fvRecovery
               })} />
             )}
            Save to DB
          </Button>
          <Link onClick={resetForm} className={classes.centerLink}>
            Reset Form
          </Link>
        </form>
      </div>

      <Snackbar
        open={snackbar.open}
        onClose={sbarClose}
        duration={snackbar.duration}
        message={snackbar.message}
        type={snackbar.type}
      />

    </>
  );
}

async function checkKeyUnique (key) {
  if (!key) {
    return false;
  }

  const found = await checkForDuplicate(key)

  if (found) {
    let err  = new Error(`BitLocker Key already exists.`);
    err.data = [ found ];
    console.log(err.data)
    throw err;
  }
  return true;
};

async function checkIdUnique (id) {
  if (!id) {
    return false;
  }

  const found = await checkForDuplicateBlId(id);

  if (found.length > 0) {
    let err  = new Error(`BitLocker Key already exists.`);
    err.data = found;
    throw err;
  }
  return true;
}


function a11yProps (index) {
  return {
    id:              `tab-${index}`,
    'aria-controls': `tabpanel-${index}`,
  };
}

function TabPanel ({ children, value, index, ...other }) {
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box padding={2}>
          {children}
        </Box>
      )}
    </Box>
  );
}
