import Context from './context';
import Layout  from './Layout';
import Page    from './Page';

export default function App () {
  return (
    <Context>
      <Layout>
        <Page />
      </Layout>
    </Context>
  );
}
