import { ApolloClient, ApolloLink, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { createAuthLink }                                                          from 'aws-appsync-auth-link';
import aws                                                                         from './aws-creds';

const link = ApolloLink.from([
  createAuthLink({
    url:    aws.graphqlEndpoint,
    region: aws.region,
    auth:   {
      apiKey: aws.apiKey,
      type:   aws.authenticationType
    }
  }),
  createHttpLink({
    uri: aws.graphqlEndpoint,
  })
]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache()

});


export default function Context ({ children, ...props }) {
  return (
    <ApolloProvider client={client} {...props} children={children} />
  );
}
